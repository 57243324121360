<template>
    <b-modal v-model="show" size="med" hide-footer :title="'Change password'" header-class="card-header pause" @hide="closePassModal" class="smallModal">
        <form @submit.prevent="savePassword">
            <div class="col-md-12">
                <div class="col-md-12" style="padding-left: 0px;">
                    <p>{{ activeLanguage.passTable.text }}<br /></p>
                    <div class="col-md-12 text-center">
                        <span v-if="password_error" class="error">
                            {{ activeLanguage.invalidPassword }}
                        </span>
                    </div>
                    <div class="form-group row">
                        <label for="currPass" class="col-sm-4 col-form-label">{{ activeLanguage.passTable.currPass }}*</label>
                            <div class="col-sm-7">
                                <input v-model="form.curr_pass" type="password" class="form-control passInput" 
                                    id="currPass" 
                                    name="currpassword"
                                    required
                                />
                            </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword3" class="col-sm-4 col-form-label">{{ activeLanguage.passTable.pass }}*</label>
                            <div class="col-sm-7">
                                <input v-model="form.password" type="password" class="form-control passInput" 
                                    id="inputPassword3" 
                                    name="password"
                                    required
                                />
                            </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputPassword4" class="col-sm-4 col-form-label">{{ activeLanguage.passTable.passRep }}*</label>
                            <div class="col-sm-7">
                                <input v-model="form.repassword" type="password"
                                class="form-control passInput" id="inputPassword4" 
                                name="repassword"
                                required
                                />
                            </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-12 col-form-label"></label>
                    <div class="col-sm-12" style="display: flex;">
                        <button type="submit" class="btn" style="margin-left: auto;">{{ activeLanguage.otherTable.saveBtn }}</button>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
    export default {
        name: "ChangePassModal",
        props: ['show', 'activeLanguage'],
        data() {
            return {
                showModal: false,
                form: {
                    password: '',
					repassword: '',
                    curr_pass: '',
                },
                password_error: false,
                showSavePassBtn: false,
            }
        },
        methods: {
            savePassword () {
                let data = {}
                let { password, repassword, curr_pass } = this.form
                if (password.length > 0) {
                    let checkPasswordMatch = password === repassword
                    if (!checkPasswordMatch) {
                        this.password_error = true
                        return false      
                    }
                }
                data = { password, curr_pass }
                
                billinkAxios.post('/app/client-settings/update',  data)
                        .then(resp => {
                            this.password_error = false
                            EventBus.$emit('showAlertModal', this.activeLanguage.success_uploaded)
                            this.closePassModal()
                        })
                        .catch(err => {
                            if (err.response.status === 400 && err.response.data.message === 'current password is not correct') {
                                EventBus.$emit('showAlertModal', this.activeLanguage.passTable.wrongCurrPass)
                            } else if (err.response.status === 400) {
                                EventBus.$emit('showAlertModal', err.response.data.message)
                            }
                            console.error(err)
                        })
            }, 
            closePassModal() {
                this.$emit('closePassModal')
            }
        },
        watch: {
            'form.repassword': function(val, oldVal) {
                if (val !== oldVal && !!val) {
                    this.showSavePassBtn = true
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .modal-content {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    .passInput {
        background: #fff !important;
        border-radius: 5px;
        color: #231f20;
        border: 1px solid #231f20;
    }
    .smallModal {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
    div#__BVID__42___BV_modal_content_ {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
</style>
<style>
@media (min-width: 992px) { 
        .modal .modal-med { 
            max-width: 40% !important; 
            width: 40% !important;
        } 
    }
</style>