<template>
    <b-modal v-model="show" size="med" hide-footer :title="'Company info'" header-class="card-header pause" @hide="closeCompanyModal">
        <div class="col-md-12">
            <div class="col-md-12">
                <div class="form-group row">
                    <label for="companyName" class="col-sm-3 col-form-label">Company name: </label>
                        <div class="col-sm-8">
                            <input v-model="companyName" type="text" class="form-control passInput" 
                                   id="companyName" 
                                   name="companyName"
                                   disabled
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="kvkNum" class="col-sm-3 col-form-label">Coc number: </label>
                        <div class="col-sm-8">
                            <input v-model="kvkNum" type="text" class="form-control passInput" 
                                   id="kvkNum" 
                                   name="kvkNum"
                                   disabled
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="btwNum" class="col-sm-3 col-form-label">Vat number: </label>
                        <div class="col-sm-8">
                            <input v-model="btwNum" type="text" class="form-control passInput" 
                                   id="btwNum" 
                                   name="btwNum"
                                   disabled
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="rekeningNum" class="col-sm-3 col-form-label">IBAN: </label>
                        <div class="col-sm-8">
                            <input v-model="rekeningNum" type="text" class="form-control passInput" 
                                   id="rekeningNum" 
                                   name="rekeningNum"
                                   disabled
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="street" class="col-sm-3 col-form-label">Street: </label>
                        <div class="col-sm-8">
                            <input v-model="street" type="text" class="form-control passInput" 
                                   id="street" 
                                   name="street"
                                   disabled
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="houseNumber" class="col-sm-3 col-form-label">House number: </label>
                        <div class="col-sm-8">
                            <input v-model="houseNumber" type="text" class="form-control passInput" 
                                   id="houseNumber" 
                                   name="houseNumber"
                                   disabled
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="extension" class="col-sm-3 col-form-label">Extension: </label>
                        <div class="col-sm-8">
                            <input v-model="extension" type="text" class="form-control passInput" 
                                   id="extension" 
                                   name="extension"
                                   disabled
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="postcode" class="col-sm-3 col-form-label">Postcode: </label>
                        <div class="col-sm-8">
                            <input v-model="postcode" type="text" class="form-control passInput" 
                                   id="postcode" 
                                   name="postcode"
                                   disabled
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="city" class="col-sm-3 col-form-label">City: </label>
                        <div class="col-sm-8">
                            <input v-model="city" type="text" class="form-control passInput" 
                                   id="city" 
                                   name="city"
                                   disabled
                            />
                        </div>
                </div>

                <!-- <div class="form-group row">
                    <label class="col-sm-12 col-form-label"></label>
                    <div class="col-sm-12" style="display: flex;">
                        <button type="submit" class="btn" @click="saveContact" style="margin-left: auto;">Save</button>
                    </div>
                </div> -->
            </div>
        </div>

    </b-modal>
</template>

<script>
    export default {
        name: "ContactModal",
        props: ['show', 'activeLanguage', 'companyName', 'street', 'houseNumber', 'postcode', 'city', 'extension', 'kvkNum', 'btwNum', 'rekeningNum'],
        data() {
            return {
            }
        },
        methods: {
            saveContact () {
                let data = {}
                data = { 
                    company_name: this.companyName, 
                    street: this.street, 
                    house_number: this.houseNumber, 
                    postcode: this.postcode, 
                    city: this.city, 
                    extension: this.extension, 
                    kvk_num: this.kvkNum, 
                    btw_num: this.btwNum, 
                    rekening_num: this.rekeningNum
                 }
                
                billinkAxios.post('/app/client-settings/update',  data)
                        .then(resp => {
                            EventBus.$emit('showAlertModal', this.activeLanguage.success_uploaded)
                            this.$emit('getInfo')
                            this.closeCompanyModal()
                        })
                        .catch(err => {
                            console.error(err)
                        })
            }, 
            closeCompanyModal() {
                this.$emit('closeCompanyInfoModal')
            }
        },
        watch: {
            // contactEmail: function(newVal, oldVal) {
            //     if (newVal !== oldVal) {
            //         this.email = this.contactEmail
            //         this.phone = this.contactPhone
            //     }
            // },
        },

            
    }
</script>

<style lang="scss" scoped>

</style>