<template>
    <div class="main">
        <div class="container">
            <div class="trxTitle">
                <h1 style="color: #4db5af;">{{ activeLanguage.title }}</h1>
            </div>

            <div class="trxMainTables">
                <div class="col-4 trxLeftCols">

                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p style="margin-top: auto; margin-bottom: auto;">{{ activeLanguage.otherTable.welcome }} {{ user.first_name }}</p>
                        </div>
                        <div class="trxTableMain" style="background-color: #F5F5F5;">
                            <div class="logoContainer" @dragover.prevent @drop.prevent>
                                <div class="logoField" @drop="dragFile($event)">
                                    <a
                                        @click.prevent="deleteLogo()"
                                        v-if="!!avatar"
                                        href=""
                                        class="deleteLogo hidden-xs"
                                    >
                                        <i class="fas fa-trash" aria-hidden="true"></i>
                                    </a>
                                    <input type="file" @change="uploadLogo($event)" class="customFileInput col-11" />
                                    <div class="logo offset-md-4" style="display: contents;" v-if="!!avatar">
                                        <img :src="avatar" class="logoImg" />
                                    </div>
                                    <div class="logoInner" v-else>
                                        <p>{{ activeLanguage.dragAndDrop }}</p>
                                        <p>{{activeLanguage.maxFileSize}} 2MB</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="trxTableTitle" style="border-top: 1px solid lightgrey;">
                            <p style="margin-top: auto; margin-bottom: auto; font-size: small;">{{ activeLanguage.otherTable.language }}</p>
                            <select class="langSelect col-7" v-model="language" @click="selectLanguage(language)">
                                <option value="english">{{ activeLanguage.otherTable.english }}</option>
                                <option value="dutch">{{ activeLanguage.otherTable.dutch }}</option>
                            </select>
                            
                        </div>
                    </div>

                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p style="margin-top: auto; margin-bottom: auto;">{{ activeLanguage.otherTable.company_info }}</p>
                            <!-- <i class="fas fa-pencil trxPlus"  aria-hidden="true" @click="openCompanyInfoModal"></i> -->
                        </div>
                        <div class="trxTableMain">
                            <div class="trxProduct trxProductListTbodyTr" style="display: flex; flex-direction: column;">
                                <p class="accountSimpleText">{{ user.company_name }}</p>
                                <p class="accountSimpleText" >{{ user.street }} {{ user.house_number }} {{ user.extension }}</p>
                                <p class="accountSimpleText" v-if="!!user.postcode" >{{ user.postcode }}, {{ user.city }}</p>
                                <p class="accountSimpleText" >{{ user.kvk_num }}</p>
                                <p class="accountSimpleText" >{{ user.btw_num }}</p>
                                <p class="accountSimpleText" >{{ user.rekening_num }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p style="margin-top: auto; margin-bottom: auto;">Contact details</p>
                            <i class="fas fa-pencil trxPlus"  aria-hidden="true" @click="openContactModal"></i>
                        </div>
                        <div class="trxTableMain">
                            <div class="trxProduct trxProductListTbodyTr" style="display: flex; flex-direction: column;">
                                <p class="accountSimpleText">{{ user.contact_email }}</p>
                                <br>
                                <p class="accountSimpleText" >{{ user.contact_phone }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p style="margin-top: auto; margin-bottom: auto;">{{ activeLanguage.otherTable.webshop_details }}</p>
                            <i class="fas fa-pencil trxPlus"  aria-hidden="true" @click="openWebshopModal"></i>
                        </div>
                        <div class="trxTableMain">
                            <div class="trxProduct trxProductListTbodyTr" style="display: flex; flex-direction: column;">
                                <p class="accountSimpleText">{{ user.web_site }}</p>
                                <p class="accountSimpleText">Terms: {{ user.webshop_av_link }}</p>
                                <p class="accountSimpleText">Return term: {{ user.webshop_return_term }} days</p>
                                <p class="accountSimpleText">Return costs: € {{ user.webshop_return_costs }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p style="margin-top: auto; margin-bottom: auto;">{{ activeLanguage.otherTable.webhookLink }}</p>
                        </div>
                        <div class="trxTableMain">
                            <p class="passText">{{ activeLanguage.otherTable.webhookText }}</p>
                            <input type="text" class="form-control" v-model="webhookLink" :placeholder="activeLanguage.otherTable.webhookLink" minlength="3" @input="showSendWebhook">
                        </div>
                        <div class="trxTableFooter" style="display: flex; cursor: default;" v-if="showWebhookBtn && webhookLink.length">
                            <button type="submit" class="btn productListTitleBtn" style="margin-left:auto; margin-right: 10px;" @click="sendLink">{{ activeLanguage.passTable.saveBtn }}</button>
                        </div>
                    </div>
                </div>

                <div class="col-8 trxRightCols">
                    <div class="trxTable">
                        <div class="trxTableTitle">
                            {{ activeLanguage.otherTable.account_details }}
                        </div>
                        <div class="trxTableMain">
                            <div class="trxProduct trxProductListTbodyTr" style="display: flex; flex-direction: column;">
                                <p class="accountSimpleText">{{ activeLanguage.otherTable.username }}: {{ user.username }}</p>
                                <p class="accountSimpleText">API Key: {{ user.uid }}</p>
                                <br>
                                <p class="accountSimpleText">{{ activeLanguage.otherTable.limit_private_orders }}:€ {{ user.product12_max_amount_private }}</p>
                                <p class="accountSimpleText">{{ activeLanguage.otherTable.limit_business_orders }}:€ {{ user.product5_max_amount ? user.product5_max_amount : user.product12_max_amount_private }}</p>
                            </div>

                            <div class="trxMainTables" style="margin-top: 30px;">
                                <div class="col-6 trxLeftCols">
                                    <div class="trxTable">
                                        <div class="trxTableTitle">
                                            <p style="margin-top: auto; margin-bottom: auto;">{{ activeLanguage.otherTable.account_security }}</p>
                                        </div>
                                        <div class="trxTableMain">
                                            <div class="trxProduct trxProductListTbodyTr" style="display: flex; flex-direction: column;">
                                                <p class="passTitle">{{ activeLanguage.otherTable.password }}</p>
                                                <p class="passText">{{ activeLanguage.otherTable.set_a_pass }}</p>
                                                <p class="passText">{{ activeLanguage.otherTable.remember_to_change }}</p>
                                                <p class="pass">1234567890</p>
                                                <button class="btn productListTitleBtn" @click="openPassModal" style="text-transform: capitalize;">{{ activeLanguage.passTable.title }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-6 trxRightCols">
                                    <div class="trxTable">
                                        <div class="trxTableTitle">
                                            <p style="margin-top: auto; margin-bottom: auto;">MFA security</p>
                                            <i class="fas fa-pencil trxPlus"  aria-hidden="true" @click="openMFAmodal"></i>
                                        </div>
                                        <div class="trxTableMain">
                                            <div class="trxProduct trxProductListTbodyTr" style="display: flex; flex-direction: column;">
                                                <p class="passText">{{ activeLanguage.otherTable.mfa_info }}</p>
                                                <p class="profileText" style="margin-top: 15px;" v-if="user.mfa_email">{{ user.mfa_email }}</p>
                                                <p class="profileText" style="margin-top: 15px;" v-if="user.mfa_phone_number">{{ user.mfa_phone_number }}</p>
                                                <p class="profileText" style="margin-top: 15px;" v-if="user.mfa_email">MFA QR ID</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="trxTable">
                        <div class="trxTableTitle">
                            <p style="margin-top: auto; margin-bottom: auto;">{{ activeLanguage.otherTable.workflow_information }}</p>
                            <button class="btn productListTitleBtn" @click="saveWorkflowInfo" v-if="workflowEdited">{{ activeLanguage.passTable.saveBtn }}</button>
                        </div>
                        <div class="trxMainTable">
                            <div class="workflowTable">
                                <div class="wfHeader" style="margin-bottom: 15px;">
                                    <div class="wfRightCol" style="width: 5%;">ID</div>
                                    <div class="wfCol" style="width: 80%;">{{ activeLanguage.otherTable.name }}</div>
                                    <!-- <div class="wfCol" style="width: 10%;">{{ activeLanguage.otherTable.fixed_fee }}</div>
                                    <div class="wfCol" style="width: 15%;">{{ activeLanguage.otherTable.percentage_fee }}</div>
                                    <div class="wfCol" style="width: 20%;">{{ activeLanguage.otherTable.faster_payout_fee }}</div> -->
                                    <div class="wfLeftCol" style="width: 20%;">{{ activeLanguage.otherTable.workflow_delay }}</div>
                                </div>
                                <div class="workflows">
                                    <div class="workflow" v-for="workflow in workflows" style="margin-bottom: 15px;" @change="workflowEdited = true">
                                        <div class="workflowRow">
                                            <div class="wfRightCol" style="width: 5%; text-align: center;">{{ workflow.id }}</div>
                                            <div class="wfCol " style="width: 80%;">{{ workflow.name }}</div>
                                            <!-- <div class="wfCol" style="width: 10%;"></div>
                                            <div class="wfCol" style="width: 15%;"></div>
                                            <div class="wfCol" style="width: 20%;"></div> -->
                                            <div class="wfLeftCol" style="width: 20%; padding: 5px;">
                                                <select class="wfSelect" v-model="workflow.start_delay">
                                                    <option value="never">Never</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                    <option value="11">11</option>
                                                    <option value="12">12</option>
                                                    <option value="13">13</option>
                                                    <option value="14">14</option>
                                                    <option value="15">15</option>
                                                    <option value="16">16</option>
                                                </select>
                                            </div>
                                        </div>
                                        <!-- <div class="workflowRow">
                                            <div class="wfRightCol" style="width: 5%; text-align: center;"></div>
                                            <div class="wfCol" style="width: 35%;">{{ activeLanguage.otherTable.price_category }} 1: 0-50 EUR</div>
                                            <div class="wfCol" style="width: 10%;">
                                                <input type="number" v-model="workflow.category1_transaction_fee" class="wfInput" />
                                            </div>
                                            <div class="wfCol" style="width: 15%;">
                                                <input type="number" v-model="workflow.category1_transaction_percent_fee" class="wfInput" />
                                            </div>
                                            <div class="wfCol" style="width: 20%;">
                                                <input type="number" v-model="workflow.category1_payout_fee" class="wfInput" />
                                            </div>
                                            <div class="wfLeftCol" style="width: 15%;"></div>
                                        </div>
                                        <div class="workflowRow">
                                            <div class="wfRightCol" style="width: 5%; text-align: center;"></div>
                                            <div class="wfCol" style="width: 35%;">{{ activeLanguage.otherTable.price_category }} 2: 50-100 EUR</div>
                                            <div class="wfCol" style="width: 10%;">
                                                <input type="number" v-model="workflow.category2_transaction_fee" class="wfInput" />
                                            </div>
                                            <div class="wfCol" style="width: 15%;">
                                                <input type="number" v-model="workflow.category2_transaction_percent_fee" class="wfInput" />
                                            </div>
                                            <div class="wfCol" style="width: 20%;">
                                                <input type="number" v-model="workflow.category2_payout_fee" class="wfInput" />
                                            </div>
                                            <div class="wfLeftCol" style="width: 15%;"></div>
                                        </div>
                                        <div class="workflowRow">
                                            <div class="wfRightCol" style="width: 5%; text-align: center;"></div>
                                            <div class="wfCol" style="width: 35%;">{{ activeLanguage.otherTable.price_category }} 3: 100+ EUR</div>
                                            <div class="wfCol" style="width: 10%;">
                                                <input type="number" v-model="workflow.category3_transaction_fee" class="wfInput" />
                                            </div>
                                            <div class="wfCol" style="width: 15%;">
                                                <input type="number" v-model="workflow.category3_transaction_percent_fee" class="wfInput" />
                                            </div>
                                            <div class="wfCol" style="width: 20%;">
                                                <input type="number" v-model="workflow.category3_payout_fee" class="wfInput" />
                                            </div>
                                            <div class="wfLeftCol" style="width: 15%;"></div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactModal 
                :showContactModal="showContactModal" 
                :activeLanguage="activeLanguage" 
                @closeContactModal="closeContactModal"
                @getInfo="getInfo" 
                :contactEmail="user.contact_email" 
                :contactPhone="user.contact_phone"
                :email_address="user.email_address" 
                :notification_email_address="user.notification_email_address" 
            />
            <WebshopModal 
                :showWebshopModal="showWebshopModal" 
                :activeLanguage="activeLanguage" 
                @closeWebshopModal="closeWebshopModal" 
                @getInfo="getInfo"
                :web_site="user.web_site" 
                :webshop_av_link="user.webshop_av_link" 
                :webshop_return_costs="user.webshop_return_costs"
                :webshop_return_term="user.webshop_return_term"
            />
            <CompanyInfoModal 
                :show="showCompanyInfoMoldal" 
                :activeLanguage="activeLanguage" 
                @closeCompanyInfoModal="closeCompanyInfoModal"
                @getInfo="getInfo" 
                :companyName="user.company_name" 
                :street="user.street"
                :houseNumber="user.house_number"
                :extension="user.extension"
                :postcode="user.postcode"
                :city="user.city"
                :kvkNum="user.kvk_num"
                :btwNum="user.btw_num"
                :rekeningNum="user.rekening_num" 
            />
            <ChangePassModal 
                :show="showPassModal" 
                @closePassModal="closePassModal" 
                :activeLanguage="activeLanguage" 
            />
            <MfaModal 
                :showMFAmodal="showMFAmodal" 
                @closeMFAmodal="closeMFAmodal" 
                @openQrModal="openQrModal" 
                :activeLanguage="activeLanguage"
                :mfa_email="user.mfa_email"
                :mfa_phone_number="user.mfa_phone_number" 
            />
            <MfaQrModal 
                :show="showQrModal" 
                :btoken_id="this.btoken.details.id" 
                :user_email="this.btoken.details.email" 
                :activeLanguage="activeLanguage.mfaTable" 
                @close="closeQrModal"
            />
            <EmailModal 
                :show="showEmailModal" 
                @closeEmailModal="closeEmailModal"
                @getInfo="getInfo" 
                :activeLanguage="activeLanguage" 
                :email_address="user.email_address" 
                :notification_email_address="user.notification_email_address" 
            />
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactModal from './ContactModal.vue'
import WebshopModal from './WebshopModal.vue'
import CompanyInfoModal from './CompanyInfoModal.vue'
import ChangePassModal from './ChangePassModal.vue'
import MfaModal from './MfaModal.vue'
import MfaQrModal from '../../components/settingsView/MfaQrModal.vue'
import EmailModal from './EmailModal.vue'

    export default {
        name: "Account",
        data() {
            return {
                user: {},
                workflows: [],
                avatar: null,
                avatarName: null,
                showCountrySelect: null,
                language: localStorage.getItem('localization'),
                showContactModal: false,
                showWebshopModal: false,
                showCompanyInfoMoldal: false,
                workflowEdited: false,
                showPassModal: false,
                showMFAmodal: false,
                showQrModal: false,
                showEmailModal: false,
                webhookLink: '',
                showWebhookBtn: false
            }
        },
        methods: {
            saveWorkflowInfo() {
                let data = {}
                data = this.workflows
                
                billinkAxios.post('/app/user-workflow/update',  data)
                        .then(resp => {
                            EventBus.$emit('showAlertModal', this.activeLanguage.success_uploaded)
                            this.getInfo()
                        })
                        .catch(err => {
                            console.error(err)
                        })
            },
            getInfo () {
                billinkAxios.get('/app/client-settings/get') 
                        .then(({ data }) => {
                            this.workflows = data.workflows
                            this.user = data.user
                            this.avatar = data.logo
                            if (data.mfa_phone_number) {
                                this.showCountrySelect = true
                            }                            
                        })
                        .catch(err => console.error(err))
            },
            dragFile(e) {
                let file = e.dataTransfer.files[0]
                let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = () => {
                        this.avatar = reader.result;
                        let data = {
                            logo: this.avatar
                        }
                            
                            billinkAxios.post('/app/client-settings/update',  data)
                                    .then(resp => {
                                        EventBus.$emit('showAlertModal', this.activeLanguage.success_uploaded)
                                        this.getInfo()
                                    })
                                    .catch(err => {
                                        console.error(err)
                                    })
                    }
                    this.avatarName = e.target.files[0].name
            },
            uploadLogo(e) {
                let file = e.target.files[0]
                let reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onloadend = () => {
                        this.avatar = reader.result;
                        let data = {
                            logo: this.avatar
                        }
                            
                            billinkAxios.post('/app/client-settings/update',  data)
                                    .then(resp => {
                                        EventBus.$emit('showAlertModal', this.activeLanguage.success_uploaded)
                                        this.getInfo()
                                    })
                                    .catch(err => {
                                        console.error(err)
                                    })
                    }
                    this.avatarName = e.target.files[0].name
            },
            deleteLogo() {
                this.avatarName = null
                this.avatar = null
            },
            selectLanguage(lang) {
				this.$store.dispatch('changeLanguage', lang);
			},
            openContactModal() {
                this.showContactModal = true
            },
            closeContactModal() {
                this.showContactModal = false
            },
            openWebshopModal() {
                this.showWebshopModal = true
            },
            closeWebshopModal() {
                this.showWebshopModal = false
            },
            openCompanyInfoModal() {
                this.showCompanyInfoMoldal = true
            },
            closeCompanyInfoModal() {
                this.showCompanyInfoMoldal = false
            },
            openPassModal() {
                this.showPassModal = true
            },
            closePassModal() {
                this.showPassModal = false
            },
            openMFAmodal() { 
                this.showMFAmodal = true
            },
            closeMFAmodal() {
                this.showMFAmodal = false
            },
            openQrModal() {
                this.showMFAmodal = false
                this.showQrModal = true
            },
            closeQrModal() {
                this.showQrModal = false
            },
            openEmailModal() {
                this.showEmailModal = true
            },
            closeEmailModal() {
                this.showEmailModal = false
            },
            sendLink() {
                let params = {
                    url: this.webhookLink
                }

                orderAxios
                    .post('webhook/set', params )
                    .then(({ data }) => {
                        this.showWebhookBtn = false
                        EventBus.$emit('showAlertModal', data.message)
                    })
                    .catch(error => {
                        console.error(error)
                        EventBus.$emit('showAlertModal', error.response.data.error)
                    })
            },
            showSendWebhook() {
                this.showWebhookBtn = true
            },
        },
        mounted() {
            this.getInfo()
        },
        computed: {
            ...mapGetters({
				activeLanguage: 'getSettingsLanguage',
                btoken: 'getBillinkToken',
			})
        },
        components: {
            ContactModal,
            WebshopModal,
            CompanyInfoModal,
            ChangePassModal,
            MfaModal,
            MfaQrModal,
            EmailModal
        }
    }
</script>

<style lang="scss" scoped>
.logoTitle {
        display: flex;
        justify-content: center;
    }
    .logoField {
        width: 100%;
        height: 200px;
        max-height: 200px;
        display: flex;
        flex-direction: column;
        text-align: center;
    }
    .logoInner {
        margin-top: auto;
        margin-bottom: auto;
    }
    .logoImg {
        margin: auto;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
    .customFileInput {
        height: 200px;
        position: absolute;
        opacity: 0;
    }
    .image {
        display: flex;
    }
    .deleteLogo {
        color: #d8271a;
        z-index: 20;
        position: absolute;
        padding: 7px;
        margin-left: 310px;
        margin-top: -15px;
    }
    .profileText {
        margin-top: 0px;
        margin-bottom: 5px;
        font-weight: 600;
        font-size: small;
    }
    .accountSimpleText {
        margin-top: 0px;
        margin-bottom: 5px;
        font-weight: normal;
        font-size: small;
    }
    .workflowTable {
        padding: 0 10px;
    }
    .wfHeader {
        width: 100%;
        display: inline-flex;
        text-align: center;
        font-weight: bold;
        font-size: smaller;
    }
    .wfRightCol {
        border: 1px solid lightgrey;
        padding: 5px;
    }
    .wfLeftCol{
        border: 1px solid lightgrey;
        padding: 5px;
    }
    .wfCol {
        border: 1px solid lightgrey;
        padding: 5px;
    }
    .workflow {
        border-top: none;
        margin-top: 0;
        margin-bottom: 0;
    }
    .workflowRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 0;
        margin-top: 0;
    }
    .wfInput {
        width: 100%;
        text-align: center;
        background-color: #F5F5F5;
        border: none;
        border-radius: 3px;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield; /* Firefox */
    }
    .noSB {
        border-left: none;
        border-right: none;
    }
    .wfSelect {
        width: 100%;
        border-color: #F5F5F5;
        padding: 2px 5px;
        text-align: center;
    }
    .langSelect {
        padding: 5px;
        border-color: lightgray;
        border-radius: 3px;
        width: max-content;
    }
    .profileText {
        margin-top: 0px;
        margin-bottom: 10px;
        font-weight: bold;
        font-size: small;
    }

    .passTitle {
        margin-top: 0px;
        margin-bottom: 5px;
        font-weight: bold;
        font-size: small;
        color: gray;
        opacity: 0.7;
    }

    .passText {
        margin-top: 0px;
        margin-bottom: 5px;
        font-weight: normal;
        font-size: small;
        color: gray;
        opacity: 0.7;
    }

    .pass {
        -webkit-text-security: disc;
        font-size: 20px;
        font-weight: bolder;
    }

</style>