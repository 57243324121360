<template>
    <b-modal v-model="showWebshopModal" size="med" hide-footer :title="'Webshop details'" header-class="card-header pause" @hide="closeWebshopModal">
        <div class="col-md-12">
            <div class="col-md-12">
                <div class="form-group row">
                    <label for="web_site" class="col-sm-3 col-form-label">Website: </label>
                        <div class="col-sm-8">
                            <input v-model="web_site" type="text" class="form-control passInput" 
                                   id="web_site" 
                                   name="web_site"
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="webshop_av_link" class="col-sm-3 col-form-label">{{ activeLanguage.otherTable.terms }}: </label>
                        <div class="col-sm-8">
                            <input v-model="webshop_av_link" type="text" class="form-control passInput" 
                                   id="webshop_av_link" 
                                   name="webshop_av_link"
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="webshop_return_term" class="col-sm-3 col-form-label">{{ activeLanguage.otherTable.days_to_return }}: </label>
                        <div class="col-sm-8">
                            <input v-model="webshop_return_term" type="text" class="form-control passInput" 
                                   id="webshop_return_term" 
                                   name="webshop_return_term"
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="webshop_return_costs" class="col-sm-3 col-form-label">{{ activeLanguage.otherTable.return_costs }}: </label>
                        <div class="col-sm-8">
                            <InputPrice
                              name="webshop_return_costs"
                              v-model="webshop_return_costs"
                              v-validate="{ required: false, is_not: '€ 0' }"
                            />
                        </div>
                </div>
                <div class="form-group row">
                <label class="col-sm-12 col-form-label"></label>
                <div class="col-sm-12" style="display: flex;">
                    <button type="submit" class="btn" @click="saveWebshop" style="margin-left: auto;">Save</button>
                </div>
            </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import InputPrice from "../../components/common/reusable/InputPrice.vue";

    export default {
        name: "WebshopModal",
        props: ['showWebshopModal', 'activeLanguage', 'web_site', 'webshop_av_link', 'webshop_return_costs', 'webshop_return_term'],
        data() {
            return {
                showModal: false,
                form: {
                    webshop: '',
                },
                showSaveWebshopBtn: false,
            }
        },
        methods: {
            saveWebshop () {
                let data = {}
                let { web_site, webshop_av_link, webshop_return_costs, webshop_return_term } = this
                webshop_return_costs = webshop_return_costs.replace('€ ', '')
                data = { web_site, webshop_av_link, webshop_return_costs, webshop_return_term }
                
                billinkAxios.post('/app/client-settings/update',  data)
                        .then(resp => {
                            EventBus.$emit('showAlertModal', this.activeLanguage.success_uploaded)
                            this.$emit('getInfo')
                            this.closeWebshopModal()
                        })
                        .catch(err => {
                            console.error(err)
                        })
            }, 
            closeWebshopModal() {
                this.$emit('closeWebshopModal')
            }
        },
        components: {
          InputPrice,
        }
    }
</script>

<style lang="scss" scoped>

</style>