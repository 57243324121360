<template>
    <b-modal v-model="showMFAmodal" size="lg" hide-footer :title="'AUTENTICATOR'" header-class="card-header pause" @hide="closeMfaModal">
        <div class="col-md-12">
            <div class="col-md-12">
                <p>{{ activeLanguage.mfaTable.subtext }}<br /></p>
                                <div class="form-group row">
                                    <label for="inputMfaEmail-search" class="col-sm-5 col-form-label">{{ activeLanguage.mfaTable.email }}</label>
                                    <div class="col-sm-7">
                                        <input v-model="form.mfa_email" 
                                                type="email" 
                                                class="form-control" 
                                                id="inputMfaEmail-search"
                                                data-lpignore="true"
                                                autocomplete="off"
                                        >
                                        <div v-show="!showEmailDisableText">
                                            <p v-show="codeWasSent" 
                                                class="codeInfoText" 
                                                :style="attemptsEmailErr ? {color: 'red' } : {}"
                                            >
                                                {{ attemptsEmailErr ? attemptsErrText : activeLanguage.mfaTable.enterEmailCode }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="showEmailCodeBlock && !showEmailDisableText">
                                    <label for="inputMfaEmailCode-search" class="col-sm-5 col-form-label code">{{ '' }}</label>
                                    <div class="col-sm-7" v-show="codeWasSent">
                                        <input type="phone" 
                                            class="form-control" 
                                            v-model="mfaEmailCode" 
                                            id="inputMfaEmailCode-search"
                                            data-lpignore="true"
                                            autocomplete="off"
                                        >
                                    </div>
                                    <div class="col-sm-5" :style="codeWasSent ? {paddingLeft: '3px'} : ''">
                                        <div v-show="!showEmailDisableBtn">
                                            <button class="btn verify" 
                                                :disabled="disableCodeBtn" 
                                                :style="disableCodeBtn ? {background: 'lightgrey'} : {}" 
                                                @click="verifyEmail(mfaEmailCode)"
                                            >
                                            {{ codeWasSent ? activeLanguage.otherTable.saveBtn : activeLanguage.mfaTable.verifyBtn }}
                                            </button>
                                        </div>
                                        <div v-show="showEmailDisableBtn">
                                            <button class="btn verify" 
                                                @click="verifyEmail(mfaEmailCode)"
                                            >
                                                {{ activeLanguage.mfaTable.disableBtn }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <br v-if="showEmailCodeBlock">
                                <div class="form-group row">
                                    <label for="inputMfaPhone-search" class="col-sm-5 col-form-label" style="white-space: pre-line;line-height: 0.8;">{{ activeLanguage.mfaTable.phone }}</label>
                                    <div class="col-sm-7">
                                        <VuePhoneNumberInput v-model="form.mfa_phone_number" @input="formatPhone()" ref="mfaPhone" default-country-code="NL" :no-country-selector="showCountrySelect"/>
                                        <div v-show="!showPhoneDisableText">
                                            <p v-show="phoneCodeWasSent" 
                                                class="codeInfoText" 
                                                :style="attemptsPhoneErr ? {color: 'red' } : {}"
                                            >
                                                {{ attemptsPhoneErr ? attemptsErrText : activeLanguage.mfaTable.enterPhoneCode }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row" v-if="showPhoneCodeBlock && !showPhoneDisableText">
                                    <label for="inputMfaPhoneCode-search" class="col-sm-5 col-form-label code">{{ '' }}</label>
                                    <div class="col-sm-7" v-show="phoneCodeWasSent">
                                        <input type="phone" 
                                            class="form-control" 
                                            v-model="mfaPhoneNumberCode" 
                                            id="inputMfaPhoneCode-search"
                                            data-lpignore="true"
                                            autocomplete="off"
                                        >
                                    </div>
                                    <div class="col-sm-5" :style="phoneCodeWasSent ? {paddingLeft: '3px'} : ''">
                                        <div v-show="!showPhoneDisableBtn">
                                            <button class="btn verify" 
                                                :disabled="disableCodeBtn" 
                                                :style="disableCodeBtn ? {background: 'lightgrey'} : {}"  
                                                @click="verifyPhone(mfaPhoneNumberCode)"
                                            >
                                                {{ phoneCodeWasSent ? activeLanguage.otherTable.saveBtn : activeLanguage.mfaTable.verifyBtn }}
                                            </button>
                                        </div>
                                        <div v-show="showPhoneDisableBtn">
                                            <button class="btn verify" 
                                                @click="verifyPhone(mfaPhoneNumberCode)"
                                            >
                                                {{ activeLanguage.mfaTable.disableBtn }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="mfaQR" class="col-sm-5 col-form-label" style="white-space: pre-line;line-height: 0.8;">{{ activeLanguage.mfaTable.mfaQr }}</label>
                                    <div class="col-sm-7">
                                        <button class="btn verify"
                                                style="max-width: none; width: 100%;" 
                                                @click="openQrModal"
                                            >
                                                {{ activeLanguage.mfaTable.authenticatorSettings }}
                                        </button>
                                    </div>
                                </div>
                                <p> {{ activeLanguage.mfaTable.mfaLabel }} </p>
            </div>
        </div>
    </b-modal>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import {mapGetters} from  "vuex"
import queryString from 'query-string'

    export default {
        name: "MfaModal",
        props: ['showMFAmodal', 'activeLanguage', 'mfa_email', 'mfa_phone_number'],
        data() {
            return {
                showModal: false,
                form: {
                    email: '',
                    phone: '',
                    mfa_email: '',
                    mfa_phone_number: '',
                    mfa_phone_number_formatted: null
                    },
                mfaEmailCode: '',
                mfaPhoneNumberCode: '',
                showEmailCodeBlock: false,
                showPhoneCodeBlock: false,
                showEmailDisableBtn: false,
                showPhoneDisableBtn: false,
                showEmailDisableText: false,
                showPhoneDisableText: false,
                codeWasSent: false,
                phoneCodeWasSent: false,
                attemptsEmailErr: false,
                attemptsPhoneErr: false,
                attemptsErrText: '',
                disableCodeBtn: false,
                qrImg: '',
                qrSecret: '',
                showCountrySelect: false,
                mfaActive: false,
                showQrModal: false,
                qrModal: false,
                qrModalData: {},
            }
        },
        watch: {
            'form.mfa_email': function(val, oldVal) {
                if (!!oldVal && val !== oldVal) {
                    this.showEmailCodeBlock = true
                    this.showEmailCodeField = true
                    this.showEmailDisableBtn = false
                    this.disableCodeBtn = false
                    this.showEmailDisableText = false
                }

                if (val === '') {
                    this.showEmailCodeField = false
                    this.showEmailDisableBtn = true
                }
            },
            'form.mfa_phone_number': function(val, oldVal) {
                if (!!oldVal && val !== oldVal) {
                    this.formatPhone(this.$refs.mfaPhone.phoneFormatted)
                    this.showPhoneCodeBlock = true
                    this.showPhoneCodeField = true
                    this.showPhoneDisableBtn = false
                    this.disableCodeBtn = false
                    this.showPhoneDisableText = false,
                    this.showCountrySelect = false
                }

                if (val === null) {
                    this.showPhoneCodeField = false
                    this.showPhoneDisableBtn = true
                    this.showCountrySelect = false
                }
            },
            mfaEmailCode: function(newVal, oldVal) {
                if(newVal !== oldVal) {
                   newVal === '' ? this.disableCodeBtn = true : this.disableCodeBtn = false
                }
            },
            mfaPhoneNumberCode: function(newVal, oldVal) {
                if(newVal !== oldVal) {
                   newVal === '' ? this.disableCodeBtn = true : this.disableCodeBtn = false
                }
            },
            mfa_email: function(newVal, oldVal) {
                if(newVal !== oldVal) {
                    this.form.mfa_email = this.mfa_email
                }
            },
            mfa_phone_number: function(newVal, oldVal) {
                if(newVal !== oldVal) {
                    this.form.mfa_phone_number = this.mfa_phone_number
                }
            }
        },
        methods: {
            openQrModal() {
                this.$emit('openQrModal')
            },
            closeMfaModal() {
                this.$emit('closeMFAmodal')
            },
            verifyEmail(emailCode) {
                let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
                let data = {
                    email: this.form.mfa_email,
                    verification_code: emailCode
                }

                billinkAxios.post('/app/client/multifactor-contacts/email', queryString.stringify(data), { headers })
                        .then(({ data }) => {
                            switch(true) {
                                case data.message === "Email with code was sent":
                                    this.codeWasSent = true
                                    this.disableCodeBtn = true
                                    break;
                                case data.message === "email is verified successfully":
                                    this.showEmailCodeBlock = false
                                    this.showEmailCodeField = false
                                    this.codeWasSent = false
                                    EventBus.$emit('showAlertModal', this.activeLanguage.mfaTable.emailSavedText)
                                    break;
                                case data.message !== "Email with code was sent":
                                    this.codeWasSent = false
                                    this.showEmailDisableText = true    
                                    EventBus.$emit('showAlertModal', this.activeLanguage.mfaTable.emailDisabledText)
                                    break;
                                default:
                                    this.showEmailCodeBlock = false
                                    this.showEmailCodeField = false
                                    this.codeWasSent = false
                                    EventBus.$emit('showAlertModal', data.message)
                            }
                            // if ( data.message !== "Email with code was sent") {
                            //     this.codeWasSent = false
                            //     this.showEmailDisableText = true    
                            //     EventBus.$emit('showAlertModal', this.activeLanguage.mfaTable.emailDisabledText)
                            // } else {
                            //     console.log('fired else', this.form.mfa_email )
                            //     this.codeWasSent = true
                            //     this.disableCodeBtn = true
                            // }

                            // if (data.message === "email is verified successfully") {
                            //     this.showEmailCodeBlock = false
                            //     this.showEmailCodeField = false
                            //     this.codeWasSent = false
                            //     EventBus.$emit('showAlertModal', this.activeLanguage.mfaTable.emailSavedText)
                            // } else {
                            //     this.showEmailCodeBlock = false
                            //     this.showEmailCodeField = false
                            //     this.codeWasSent = false
                            //     EventBus.$emit('showAlertModal', data.message)
                            // }
                        })
                        .catch(err => {
                            console.error(err)
                            if(err.response.data.error.includes('Attempts left')) {

                                this.attemptsEmailErr = true
                                let attempts = err.response.data.error[err.response.data.error.indexOf(':') + 2]
                                this.attemptsErrText = `${this.activeLanguage.mfaTable.attemptsLeft + attempts}`

                                this.codeWasSent = true
                                this.disableCodeBtn = true
                                this.mfaEmailCode = ''
                            } else {
                                this.codeWasSent = false
                                this.showEmailCodeBlock = false
                                this.mfaEmailCode = ''
                                this.attemptsEmailErr = false
                                this.attemptsErrText = ''
                                this.form.mfa_email = ''
                            }
                            if (err.response.status === 400) {
                                EventBus.$emit('showAlertModal', err.response.data.error)
                            }
                        })
            },
            verifyPhone(phoneCode) {
                let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
                let data = {
                    phone_number: this.form.mfa_phone_number_formatted,
                    verification_code: phoneCode
                }
                
                billinkAxios.post('/app/client/multifactor-contacts/phone', queryString.stringify(data), { headers })
                        .then(({ data }) => {
                            if (!this.form.mfa_phone_number_formatted) {
                                this.phoneCodeWasSent = false
                                this.showPhoneDisableText = true
                                this.showCountrySelect = false
                                EventBus.$emit('showAlertModal', this.activeLanguage.mfaTable.smsDisabledText)
                            } else {
                                this.phoneCodeWasSent = true
                                this.disableCodeBtn = true
                            }

                            if (data.message === "phone number is verified successfully") {
                                this.showPhoneCodeBlock = false
                                this.showPhoneCodeField = false
                                this.phoneCodeWasSent = false
                                this.showCountrySelect = false
                                EventBus.$emit('showAlertModal', this.activeLanguage.mfaTable.smsSavedText)
                            }
                        })
                        .catch(err => {
                            console.error(err)
                            if(err.response.data.error.includes('Attempts left')) {

                                this.attemptsPhoneErr = true
                                let attempts = err.response.data.error[err.response.data.error.indexOf(':') + 2]
                                this.attemptsErrText = `${this.activeLanguage.mfaTable.attemptsLeft + attempts}`

                                this.phoneCodeWasSent = true
                                this.disableCodeBtn = true
                                this.mfaPhoneNumberCode = ''
                            } else {
                                this.phoneCodeWasSent = false
                                this.showPhoneCodeBlock = false
                                this.mfaPhoneNumberCode = ''
                                this.attemptsPhoneErr = false
                                this.attemptsErrText = ''
                                this.form.mfa_phone_number = ''
                                this.form.mfa_phone_number_formatted = ''
                            }
                        })
            },
            formatPhone() {
                if(this.$refs.mfaPhone.phoneFormatted) {
                    return this.form.mfa_phone_number_formatted = parseInt(this.$refs.mfaPhone.phoneFormatted.replace(/\s+/g, ''))
                } else {
                    return this.form.mfa_phone_number_formatted = null
                }
            }
        },
        computed: {
			...mapGetters({
                btoken: 'getBillinkToken',
			})
		},
        components: {
            VuePhoneNumberInput
        }
    }
</script>

<style lang="scss" scoped>

</style>