<template>
    <b-modal v-model="show" size="med" hide-footer :title="'Email addresses'" header-class="card-header pause" @hide="closeEmailModal">
        <div class="col-md-12">
            <div class="col-md-12" style="padding-left: 0;">
                <p>You can choose to have different email address for login and invoice notifications.</p>
                <div class="form-group row">
                    <label for="email" class="col-sm-5 col-form-label">Email address</label>
                    <div class="col-sm-7">
                        <input v-model="email" type="email" class="form-control passInput"/>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="notificationEmail" class="col-sm-5 col-form-label">Notification email address</label>
                    <div class="col-sm-7">
                        <input v-model="notificationEmail" type="email" class="form-control passInput"/>
                    </div>
                </div>
                <div class="form-group row">
                <label class="col-sm-12 col-form-label"></label>
                <div class="col-sm-12" style="display: flex;">
                    <button type="submit" class="btn" @click="saveEmails" style="margin-left: auto;">{{ activeLanguage.otherTable.saveBtn }}</button>
                </div>
            </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: "EmailModal",
        props: ['show', 'activeLanguage', 'btoken_id', 'email_address', 'notification_email_address'],
        data() {
            return {
                showModal: false,
                email: null,
                notificationEmail: null
            }
        },
        mounted(){
        },
        watch: {
            email_address: function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.email = this.email_address
                    this.notificationEmail = this.notification_email_address
                }
            },
        },
        methods: {
            closeEmailModal() {
                this.$emit('closeEmailModal')
            },
            saveEmails() {
                let data = {
                    email_address: this.email,
                    notification_email_address: this.notificationEmail
                }
                billinkAxios.post('/app/client-settings/update',  data)
                        .then(resp => {
                            EventBus.$emit('showAlertModal', this.activeLanguage.success_uploaded)
                            this.$emit('getInfo')
                            this.closeEmailModal()
                        })
                        .catch(err => {
                            if (err.response.data.message === 'current password is not correct') {
                                EventBus.$emit('showAlertModal', this.activeLanguage.passTable.wrongCurrPass)
                            }
                            console.error(err)
                        })
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>