import { render, staticRenderFns } from "./MfaModal.vue?vue&type=template&id=cff9834e&scoped=true&"
import script from "./MfaModal.vue?vue&type=script&lang=js&"
export * from "./MfaModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff9834e",
  null
  
)

export default component.exports