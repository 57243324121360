<template>
    <b-modal v-model="showModal" size="lg" hide-footer :title="'AUTENTICATOR'" header-class="card-header pause" @hide="closeQrModal">
        <div class="mfa-container">
            <div class="mfa-text">
                <p class="mfa-header">
                    {{ mfaActive ? activeLanguage.mfaHeaderEnabled : activeLanguage.mfaHeader }}
                </p>
                <p class="mfa-text-info">
                    {{ activeLanguage.mfaText }}
                </p>
                <div class="mfa-steps">
                    <p>
                        {{ activeLanguage.mfaAppStep1 }}
                    </p>
                    <p>
                        {{ activeLanguage.mfaAppStep2 }}
                    </p>
                    <p v-if="!mfaActive">
                        {{ activeLanguage.mfaAppStep3 }}
                    </p>
                </div>
                <div class="mfa-input" v-if="!mfaActive">
                    <input v-model="verificationCode" type="text" class="form-control" style="width: 150px;">
                    <div class="col-sm-4">
                                    <button class="btn verify" 
                                            @click="activateQrModal"
                                        >
                                            {{ activeLanguage.activate }}
                                    </button>
                                </div>
                </div>
                <div class="mfa-disable" v-else>
                    <div class="mfa-disabled-text">
                        <h2 class="mfa-header">
                            {{ activeLanguage.mfaDisable }}
                        </h2>
                        <p class="mfa-text-info">
                            {{ activeLanguage.mfaDisableText }}
                        </p>
                    </div>
                    <div class="mfa-buttons">
                        <div class="col-sm-4"  style="margin-left: -17px;">
                            <button class="btn verify btn-secondary" 
                                   @click="closeQrModal"
                            >
                                    {{ activeLanguage.goBack }}
                         </button>
                        </div>
                    <div class="col-sm-4" style="margin-left: -50px;">
                        <button class="btn verify secondary deactivateBtn" 
                                @click="disableQrModal"
                        >
                                {{ activeLanguage.deactivate }}
                        </button>
                    </div>
                    </div>

                </div>
            </div>

            <div class="mfa-image">
                <div class="qr">
                    <img :src="qrImg" class="img-responsive" alt="" width="270">
                </div>
                <p class="qrId">
                    ID : {{ qrSecret }}
                </p>

            </div>
        </div>
        
    </b-modal>
</template>

<script>
    import queryString from 'query-string'

	export default {
		name: "MfaQrModal",
        props: ['show', 'activeLanguage', 'btoken_id', 'user_email'],
        data() {
			return {
                showModal: false,
                verificationCode: null,
                mfaActive: false,
                qrImg: null,
                qrSecret: null
            }
        },
        mounted(){
            if (!!this.show) {this.checkQrStatus()}
            if (!!this.show) {this.getQr()}
        },
        watch: {
            show: function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.showModal = newVal
                    if (newVal) {
                        this.getQr()
                        this.checkQrStatus()
                        }
                }
            }
        },
        methods: {
            getQr() {
                let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
                billinkAxios
                        .post('/app/client/authenticator/pair',  { headers })
                        .then(({ data }) => {
                            this.qrImg = data.qr_img
                            this.qrSecret = data.qr_secret
                        })
                        .catch(err => console.error(err))
            },
            activateQrModal() {
                let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
                let data = {
                    verification_code: this.verificationCode
                }
                billinkAxios
                        .post('/app/client/authenticator/verify', queryString.stringify(data), { headers })
                        .then(({ data }) => {
                            if (data.message) EventBus.$emit('showAlertModal', this.activeLanguage.mfaCoupled)
                            this.checkQrStatus()
                            this.closeQrModal()
                        })
                        .catch(err => {
                            console.error(err)
                            EventBus.$emit('showAlertModal', this.activeLanguage.incorrectCode)
                            })
            },
            disableQrModal() {
                let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
                billinkAxios
                        .post('/app/client/authenticator/unpair', { headers })
                        .then(({ data }) => {
                            if (data.message) EventBus.$emit('showAlertModal', this.activeLanguage.mfaUncoupled)
                            this.checkQrStatus()
                            this.closeQrModal()
                        })
                        .catch(err => console.error(err))
            },
            closeQrModal() {
                this.$emit('close')
            },
            checkQrStatus(){
                let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
                billinkAxios
                        .get(`/app/client/authenticator/paired` , { headers })
                        .then(({ data }) => {
                            this.mfaActive = data.enabled
                        })
                        .catch(err => console.error(err))
            }
        }
	}
</script>

<style lang="scss" >

    .mfa-header {
        margin-bottom: 10px;
        font-weight: 900;
        font-size: 16px;
    }
    .mfa-container {
        display: flex;
        flex-direction: row;
        padding: 20px;
    }
    .mfa-text {
        flex-direction: column;
        width: 65%;
    }
    .mfa-steps {
        padding-left: 15px;
        color: gray;
    }
    .mfa-input {
        display: flex;
    }
    .mfa-buttons {
        display: flex;
    }
    .mfa-image {
        padding-left: 15px;
    }
    .qrId {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        text-align: center;
        font-size: 20px;
    }
    .deactivateBtn {
        opacity: 0.65;
    }


    @media (min-width: 992px) {
        .modal-lg {
             max-width: 930px !important;
         }
    }
 

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 930px !important;
    }
}
  


</style>