<template>
    <b-modal v-model="showContactModal" size="med" hide-footer :title="'Contact details'" header-class="card-header pause" @hide="closeContactModal">
        <div class="col-md-12">
            <div class="col-md-12">
                <div class="form-group row">
                    <label for="email" class="col-sm-4 col-form-label">{{ activeLanguage.otherTable.customer_service_email }}: </label>
                        <div class="col-sm-7">
                            <input v-model="email" type="email" class="form-control passInput" 
                                   id="email" 
                                   name="email"
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="phone" class="col-sm-4 col-form-label">{{ activeLanguage.otherTable.customer_service_phone }}: </label>
                        <div class="col-sm-7">
                            <input v-model="phone" type="phone" class="form-control passInput" 
                                   id="phone" 
                                   name="phone"
                            />
                        </div>
                </div>
                <div class="form-group row">
                    <label for="email" class="col-sm-4 col-form-label">Email address</label>
                    <div class="col-sm-7">
                        <input v-model="email_address" type="email" class="form-control passInput"/>
                    </div>
                </div>
                <div class="form-group row">
                    <label for="notificationEmail" class="col-sm-4 col-form-label">Notification email address</label>
                    <div class="col-sm-7">
                        <input v-model="notification_email_address" type="email" class="form-control passInput"/>
                    </div>
                </div>
                <div class="form-group row">
                <label class="col-sm-12 col-form-label"></label>
                <div class="col-sm-12" style="display: flex;">
                    <button type="submit" class="btn" @click="saveContact" style="margin-left: auto;">Save</button>
                </div>
            </div>
            </div>
        </div>

    </b-modal>
</template>

<script>
    export default {
        name: "ContactModal",
        props: ['showContactModal', 'activeLanguage', 'contactEmail', 'contactPhone', 'email_address', 'notification_email_address'],
        data() {
            return {
                email: null,
                phone: null,
            }
        },
        methods: {
            saveContact () {
                let data = {}
                data = { 
                    contact_email: this.email, 
                    contact_phone: this.phone,
                    email_address: this.email_address,
                    notification_email_address: this.notification_email_address 
                }
                
                billinkAxios.post('/app/client-settings/update',  data)
                        .then(resp => {
                            EventBus.$emit('showAlertModal', this.activeLanguage.success_uploaded)
                            this.$emit('getInfo')
                            this.closeContactModal()
                        })
                        .catch(err => {
                            console.error(err)
                            if (err.response.status === 400) {
                                EventBus.$emit('showAlertModal', err.response.data.message)
                            }
                        })
            }, 
            closeContactModal() {
                this.$emit('closeContactModal')
            }
        },
        watch: {
            contactEmail: function(newVal, oldVal) {
                if (newVal !== oldVal) {
                    this.email = this.contactEmail
                    this.phone = this.contactPhone
                }
            },
        },

            
    }
</script>

<style lang="scss" scoped>

</style>